<template>
  <div style="margin-bottom: 2px; background-color: #f9f9f9" class="grid has-11-columns pt-075 pb-075 rounded-sml is-uppercase tracking-wide has-text-grey" >
    <div class="is-1-column"></div>
    <div class="is-2-columns">Field Label</div>
    <div class="is-2-columns">Placeholder</div>
    <div class="is-2-columns">Default</div>
    <div class="is-1-columns">Required</div>
    <div class="is-2-columns"></div>
    <div class="is-1-column"></div>
  </div>
</template>
<script>
  export default {

  }
</script>