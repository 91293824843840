<template>
  <loader v-bind="{ loading }" >
    <div class="is-flex justify-between">
      <div class="is-flex align-items-center">
        <braces-icon :size="32" :color="'has-text-primary'" style="margin-bottom: 0.3rem; margin-right: 0.5rem" />
        <h3 class="is-size-4">Custom Fields</h3>
        <icon
            icon="info-circle"
            class="ml-1 mb-1 is-small has-text-grey-lighter has-tooltip-right has-tooltip-multiline"
            :data-tooltip="customFieldsTooltip"/>
      </div>
      <div>
        <action-button
            :working="working"
            :disabled="assetType.locked || addingCustomField"
            left-icon="plus-circle"
            class="is-primary is-inverted rounded-sml has-text-weight-semibold mr-1"
            @click="addingCustomField = true">
          Add New
        </action-button>
        <action-button
            :working="working"
            :disabled="!canSave || assetType.locked"
            left-icon="save"
            class="is-success rounded-sml has-text-weight-semibold"
            @click="store">
          Save
        </action-button>
      </div>
    </div>
    <div class="mt-2 pb-6">
      <div v-if="hasFields">
      <custom-fields-table-header/>
      <draggable
          handle="#handle"
          :animation="200"
          v-model="assetType.custom_fields"
          group="custom_fields">
        <transition-group>
          <custom-field
              v-for="field in assetType.custom_fields"
              :field="field"
              :locked="assetType.locked"
              :key="field.field_id"/>
        </transition-group>
      </draggable>
      </div>
      <message v-if="hasDuplicateLabel" class="is-danger mt-2">Two of your {{ Naming.CustomFields }} have the same label. The field label should be unique for this {{ Naming.AssetType }}.</message>
      <message v-if="hasAnEmptyLabel" class="is-danger mt-2">One of your {{ Naming.CustomFields }} has an empty label.</message>
      <message v-if="hasDuplicateFieldId" class="is-danger mt-2">Two of your {{ Naming.CustomFields }} have the same ID. Please remove the new field and re-add it.</message>

      <new-custom-field :adding="addingCustomField" class="mt-2 mb-2" v-if="!assetType.locked" />
    </div>

  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomField from './partials/CustomField'
import NewCustomField from './partials/NewCustomField'
import arrayHelper from '@/utils/arrayHelper.js'
import CustomFieldsTableHeader from "@/views/asset-type/partials/CustomFieldsTableHeader.vue";

export default {

  components: {
    CustomFieldsTableHeader,
    CustomField,
    NewCustomField
  },

  data: () => ({
    loading: true,
    working: false,
    saved: false,
    addingCustomField: false
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetType/loadAssetType', this.$route.params.assetType)
    ]).then(() => this.loading = false)
  },

  methods: {
    store() {
      this.working = true
      this.$store.dispatch('assetType/save').then(() => {
        this.working = false
        this.saved = true
        this.$toast.success(this.Convert(this.$lang.assetType.saved))
      }).catch(() => {
        this.working = false
        this.saved = false
        this.$whoops()
      })
    },
    async confirmLeave() {
      return this.$confirm({
        title: "Warning",
        message: `Leave without saving?`,
        confirmText: "Leave Page",
        cancelText: "Continue",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    hasFields(){
      return this.assetType.custom_fields?.length > 0
    },
    customFieldsTooltip(){
      return `${this.Naming.CustomFields} are considered fixed data points. These values will typically not change over time and are best suited for things like serial numbers etc. For data points that fluctuate or change, consider creating a ${this.Naming.Check.toLowerCase()} on a ${this.Naming.Checklist.toLowerCase()} instead.`
    },
    canSave() {
      return !(this.hasAnEmptyLabel || this.hasDuplicateLabel || this.hasDuplicateFieldId);
    },
    hasAnEmptyLabel() {
      return this.assetType.custom_fields?.some(customField => !customField.label || customField.label.trim().length < 1)
    },
    hasDuplicateLabel() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'label').length > 0
    },
    hasDuplicateFieldId() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'field_id').length > 0
    },
    async beforeRouteLeave(to, from, next) {
      if (!this.saved) {
        if (await this.confirmLeave()) {
          next()
        }
      } else {
        next()
      }
    }
  }
}
</script>